#admin-menu, #admin-menu .dropdown{
	z-index: 9999; 
}

.brand-primary {color:@brand-primary;}       
.brand-success {color:@brand-success;}       
.gray-dark     {color:@gray-dark;}       
.gray          {color:@gray;}       
.gray-light    {color:@gray-light;}       

.readmore-paragraph{
	margin-top: 9px;
}

h1{
	margin-bottom: 33px;
}
.sectionBox ul.pagination li{
	margin-bottom: auto;
	padding-left: 0;
	position: relative;
	float: left;
	.clearfix;	
	&:before{
		display: none;
	}
}

.partner-page .hidePartner{
	display: none;
}

.retina-image-link{
	display: none;
	& + a {
		display: block;
	}

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		
	display: block;
	& + a {
		display: none;
	}
		
	}

}

.retina-image{
	display: none;
	& + img {
		display: block;
	}

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		
	display: block;
	& + img {
		display: none;
	}
		
	}

}

.navbar-is-static-top img.pull-left{
	padding-right: 29px;
	padding-bottom: 29px;
	& + h1,	& + h2,	& + h3,	& + h4,	& + h5,	& + h6,	& + .h1, & + .h2, & + .h3, & + .h4, & + .h5, & + .h6{
		margin-bottom: 0;
		& + p + img{
			clear: both;
		}
	}

}
img.pull-right{
	padding-left: 17px;
	padding-bottom: 17px;	
}

.panel-group{
	& + a{
		display: block;
		width: 100%;
		text-align: center;
		color: @brand-primary;
	}
}

#accordion{
	max-width: 825px;
	margin-right: auto;
	margin-left: auto;
}
p.lg{
	font-size: 20px;
	margin: 24px 0 0;
	display: block;
	position: relative;
	padding-left: 59px;
	// line-height: 28px;
	.clearfix;
	&:first-child{
		margin-top: 0;
	}
	[class^="carh-ico"], [class*=" carh-ico"]{
		font-size: 36px;
		// width: 75px;
		text-align: center;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -19px;
	}
	@media (max-width: @grid-float-breakpoint) {
		margin-top: 17px;	
		font-size: 18px;
		line-height: 21px;
		max-width: 305px;
		// min-height: 42px;
		&:first-child{
			margin-top: 0;
		}		
	}	
}

body.front,
body.page-node-full-width{
	blockquote{
		@media (max-width: @grid-float-breakpoint) {
			font-size: 16px;
			padding: 12px 37px;
		}

	}
} 

body.sidebar-second.not-front {

	.node-page .container,
	.sectionBox .container{ 
		.container-fluid();
		width: auto !important;
	}
	.sectionBox{
		padding: 0;
	}
	.mainContent > div > .col-md-8.col-sm-12 {
		padding-bottom: 45px;
	}
	.sectionBox + .testimonialsBox{
		padding-top: 50px;
	}
	.testimonialsBox{
		h2.text-center{
			text-align: left;
		}
		blockquote{
			padding: 51px 101px;
			font-size: 22.4987px;
			@media (min-width: @grid-float-breakpoint) {

				&:before{
					left: 42px;
				}
				&:after{
					right: 16px;
					bottom: 126px;
				}	
				footer{
					margin-top: 39px;
				}	
			}
			@media (max-width: @grid-float-breakpoint) {
				font-size: 16px;
				padding: 12px 37px;
			}
		}
		@media (min-width: @grid-float-breakpoint) {
			.carousel-indicators{
				bottom: 26px; 
			}
		}
	}
}
// global
body.not-front {
	background-color: #eeeeee;
	&.page-node-full-width{
		background-color: #fff;
	}
	@media (min-width: @grid-float-breakpoint) {
		&.no-menu-children,
		&.no-sidebars.page-sitemap{
			header.navbar {
				&:after{
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 50px;
					background-color: @brand-primary;
					left: 0;
					bottom: 0;

				}
			}
		}
	}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			background-color: #fff;

		}
}

.node p + ul.list-unstyled{
	margin-top: -6px;	
}
.node,
.sectionBox {
	ul{
		padding: 0;
		list-style: none;
		font-weight: 500;
		margin-top: 17px;
		> li{
			margin-bottom: 11px;
			padding-left: 36px;
			position:relative;
			&:before{
				color: #ffab00;
				content:"\e013";
				.glyphicon();
				position:absolute;
				left: 1px;
				top: 4px;
			}
		}
		&.list-unstyled{
			margin-top: 0;
			li{
				margin-bottom: 8px;
				padding-left: 20px;	
				&:before{
					display: none;
				}
			}
		}
		&.list-question-after{
			> li{
				&:after{
					content: '';
					display: inline-block;
					background: url(../images/list_question.png) no-repeat;
					width: 20px;
					height: 20px;
					vertical-align: sub;
					margin-left: 12px;
				}
			}
		}
		&.list-active{
			> li:first-child{
				&:before{
					color: @brand-primary;	
				}			
			} 
			&:hover, &:focus, &:active{
				// display: none;
				> li:first-child{
					&:before{
						color: #8e8e8e;	
					}
				} 			
			}
		}
		&.list-gray {
			> li {
				&:before{
					color: #8e8e8e;
				}
				&:hover{
					&:before{
						color: @brand-primary;	
					}
				}
			}
		}
		
	}

	ul.list-regular{

		li{
			padding-left: 22px;
			&:before{
				content: '•';
				color: inherit;
				font-size: 18px;
				font-family: monospace;
			}
		}
	}
}
.navbar-is-static-top p + ul.list-regular{
	margin-top: -23px; 
}
.logo{
	width: 267px;
	margin-top: 40px;
	height: 52px;
	
	@media (max-width: @grid-float-breakpoint) {
		display:block;
		width: 132px;
		height:26px;
		margin-top: 15px;
		float:none !important;
		margin-left: 94px;
	}
	img{
		width: 100%;
		height: 100%;
	}
}
img[src$='.svg']{
	display:none;
}
html.svg{
	img[src$='.svg']{
		display:block;
		& + img[src$='.png']{
			display:none;
		}
	}
}

// nav
body.front .navbar{
	margin-bottom: 0;
}
.navbar{
	background-color: #fff;
	@media (max-width: @grid-float-breakpoint) {
		min-height: 60px;
		.box-shadow(0 8px 6px -6px #f9f9f9);
	}
}
.navbar-header{
	width:100%;
	padding-bottom: 32px;
	@media (max-width: @grid-float-breakpoint) {
		.navbar-toggle{
			position:absolute;
			left:5px;
			top:5px;
		}
	}
}

.top-nav{
	li.dropdown {
		&:hover,&:focus{
			& > a {
					& + .dropdown-menu {
						display:block;
					}
				}
		}
		
	}
	@media (min-width: @grid-float-breakpoint) {
		font-size: 22px;
		margin-top: -67px;
		.caret{
			display:none;
		}
	}
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		.navbar-nav{
			font-size: 17px;
		}
		.dropdown-toggle + .dropdown-menu > li > a {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	@media (max-width: @grid-float-breakpoint) {
		float:none !important;
	}

	.user_nav{
		.clearfix;
		margin-top: 40px;
		li.btn{
			border-radius: 5px;
			border-color: #ebebeb;
		}
		@media (max-width: @grid-float-breakpoint) {
			padding: 0;
			margin-top: 44px;
			margin-bottom: 38px;
			margin-left: -15px;
			margin-right: -15px;
			text-align: center;
			li{
				float: none;
				display: inline-block;
				box-shadow: none;
				border-radius: 18px;
				border: 1px solid #efefef;
				&:first-child{
					border-bottom-left-radius: 13px;
					border-top-left-radius: 13px;
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;	

				}
				& + li.btn{
					border-bottom-right-radius: 13px;
					border-top-right-radius: 13px;	
					margin-left: -6px;
				}
			}
		}
	}
}

body.not-front {
	.navbar{
		margin-bottom: 0;
		@media (min-width: @grid-float-breakpoint) {
			padding-bottom: 61px;
		}
	}
	.top-nav{
		@media (min-width: @grid-float-breakpoint) {
			& > ul{
				& > li{
					&.active-trail.dropdown{
						& > a{
							position:relative;
							&:before{
								content:'';
								position: absolute;
								margin-left: -16.5px;
								left: 50%;
								bottom: -29px;
								width: 0;
								height: 0;
								border-left: 16.5px solid transparent;
								border-right: 16.5px solid transparent;
								border-bottom: 18px solid @brand-primary;
							}
							& + ul{
								display: block;
							}
						}
					}

					&.active-trail.dropdown > a{
						& + ul{
							width: 100%;
							top: 73%;
							text-align: center;
							border: 0;
							background-color: @brand-primary;
							min-height: 50px;
							border: 0;
							border-radius: 0;
							padding:10px 0;
							li{
								float: none;
								display:inline-block;
								&.open,
								&.active-trail{
									a{
										background: transparent;
										color: #fff;
									}
								}
								a{
									color:#7fd4b2;
									&.active{
										color:#fff;
									}
									&:hover{
										background-color: transparent;
										color: #fff;
									}
								}
							}
						}
					}
					&.dropdown{
						z-index: 3333;
						position: relative;
						&:hover, &:focus{
							> a{
								& + .dropdown-menu{
									display:block;
								}
							}
						}
					}
					&.dropdown.active-trail {
						position: static;
						z-index: 3332;
						&.open{
							position: relative;
							.dropdown-menu{
								z-index: 3333;
							}
						}
						&.open.active-trail{
							position: static;
						}						
					}
				}
			}
		}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			.navbar-nav{
				font-size: 17px;
			}
			.dropdown-toggle + .dropdown-menu > li > a {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		@media (max-width: @grid-float-breakpoint) {
			float:none !important;
			border-top: 2px solid #f9f9f9;
			.box-shadow( inset 0 8px 6px -6px #f9f9f9);
		}
	}
}

.user_nav{
	>li{
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		background-color: #fafafa;
		font-size: 14px;
		line-height: 1;
		padding-top: 12px;
		letter-spacing: -0.6px;
		padding-bottom: 11px;
		padding-left: 28px;
		padding-right: 28px;
		font-family: 'Montserrat', sans-serif;
		border-top: 0;
		border-color: #ebebeb;
		&:first-child{
			border-right-color:#fff;
			& ~ li{
				border-left-color:#fff;
			}
		}

	}
}

.crossSite{
	background: #202733;
	padding: 10px 0;
	text-align: center;
}

.mainContent{
	.row > section{
		background-color: #fff;
		&:before{
			content: '';
			display: block;
			height:100%;
			width:100%;
			background-color: #fff;
			position:absolute;
			left:-100%;
			top:0;
			z-index: 1;

		}
	}
	.row > aside{
		padding-bottom: 45px;	
	}
}

//

#svg {
  	position: absolute;
  	top: 0;
  	left: 0;
  	z-index: 1;	
    display: table-cell;
    vertical-align: middle;   	
}
#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #ffab00;
  stroke-width: 2px;
  z-index: 2;
  .opacity(.5);
  & + circle{
  	stroke-width: 6px;
  }
}
#svg #bar {
  stroke: #FF9F1E;
  // stroke-dashoffset: 448.93801px;
}
.cont {
  display: block;
  height: 211px;
  width: 211px;
  // margin: 2em auto;
  // box-shadow: 0 0 1em black;
  border-radius: 100%;
  position: relative;
  img{
  	position: absolute;
  	top: 0;
  	left: 0;
  	z-index: 1;
    display: table-cell;
    vertical-align: middle; 
  .opacity(.5);

  }
  .data{
  	z-index: 10;
	margin: auto auto;
  }
  &.invert{
  	#svg circle,
	#svg #bar {
  		stroke: #3eb3ba;
  	}
  }
}
#cont:after {
  // position: absolute;
  // display: block;
  // height: 160px;
  // width: 160px;
  // left: 50%;
  // top: 50%;
  // // box-shadow: inset 0 0 1em black;
  // background-color:#fff;
  // content: attr(data-pct)"%";
  // // content: '';
  // margin-top: -80px;
  // margin-left: -80px;
  // border-radius: 100%;
  // line-height: 160px;
  // font-size: 2em;
  // text-shadow: 0 0 0.5em black;
}



.data{
	// float: left;
	display: inline-block;
	padding: 48px;
	background: url(../images/circular_box.png) no-repeat center center transparent;
	font-size: 15px;
	text-align: center;
	box-sizing: content-box;
	color: @gray-dark;
	.square(118px);
	line-height: 16px;
	letter-spacing: -0.4px;
	&:first-child{
		& + .data{
			background-image: url(../images/circular_box_2.png); 
			& ~ .data{
				background-image: url(../images/circular_box.png); 
			}
		}
	}
	span{
		display: block;
		font-size: 45px;
		font-weight: 500;
		line-height: 1.4;
		letter-spacing: -1.2px;
		small{
			font-size: 30px;
		}
		sup{
			font-size: 26px;
			vertical-align: super;
			top: 0;	
			left: -2px;
		}
		sub{
			bottom: 0;
			vertical-align: baseline;
			bottom: 0;
			font-size: 26px;
		}
	}
}

.circular_box_1{
	background-image: url(../images/circular_box_1.png); 
}
.circular_box_2{
	background-image: url(../images/circular_box_2.png); 
}

.data-slider {
	padding-bottom: 40px;
	.carousel-indicators {
		bottom: 0px;
	}
	.data{
		float: none;
		margin: 0 auto;
	}
	.item{
		&:first-child{
			& + .item{
					.data{
						background-image: url(../images/circular_box_2.png); 
					}
				& ~ .item{
					.data{
						background-image: url(../images/circular_box.png); 
					}
				}
			}
		}		
	}
	
}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.data{
		margin: 0 1.6%;
			padding: 28px;
			background-size: cover;
			font-size: 12px;
			.square(100px);

			span{
				font-size: (45px / 2px);
				small{
					font-size: (30px / 2px);
				}
				sup{
					font-size: (26px / 2px);
				}
				sub{
					font-size: (26px / 2px);
				}
			}			
	}
}
// Main Footer
.main-footer{
	background-color: #454f5d;
	padding-top: 29px;
	padding-bottom: 14px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	.head{

		h2, .h2{
			display: block;
			font-family: Montserrat;
			font-size: 23px;
			letter-spacing: -0.4px;
			margin-bottom: 2px;
			& + p {
				font-size: 17px;
			}
		}
		position:relative;
		max-width: 310px;
		margin:0 auto;
	}
	a, .cRight{
		color:#9fa3aa;
	}
	@media (max-width: @grid-float-breakpoint) {
		.cRight{
			margin-top: -9px;
		}
	}
	.social{
		@media (min-width: @grid-float-breakpoint) {
			display:inline-block;
			position:absolute;
			top:4px;
			right:-123px;
		}
		@media (max-width: @grid-float-breakpoint) {
			display:block;
			margin-top: 25px;
		}
		> *{
			font-size: 41px;
			line-height: 1;
		}
		a{
			color:#565f6e;
			position:relative;
			// background:#fff;
			&:before{
				z-index: 5;
				position:relative;
			}
			&:after{
				content: '';
				width: 20px;
				height: 20px;
				background-color: #fff;
				display:block;
				position:absolute;
				left:50%;
				top:50%;
				margin-top: -10px;
				margin-left: -10px;
				z-index: 1;
			}
			& + a{
				margin-left: 9px;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
	.footer-nav{
		display:inline-block;
		margin-right: 7px;
		margin-top: 34px;
		@media (max-width: @grid-float-breakpoint) {
			max-width:300px;
			margin-top: 30px;
		}
		li{
			&:after{
				content:'';
				display:inline-block;
				background-color: #565f6e;
				width:2px;
				height:12px;
				vertical-align: middle;
				margin-left: 14px;

			}
			& + li{
			}
		}
	}
}
// Cross Site Nav
.cross-site-nav{
	background-color: #2a3342;
	text-align: center;
	font-size: 27px;
	min-height: 51px;
	&:before{
		content:'Other Veda sites';
		font-size: 14px;
		color: #fff;
		color: rgba(255, 255, 255, 0.5);
		margin-right: 12px;
	}
	* {
		color: #fff;
		color: rgba(255, 255, 255, 0.5);
		transition: color 0.5;
		vertical-align: middle;
	}
	a{
 		& + a{
 			margin-left: 10px;
 		}
		&:hover{
			color: rgba(255, 255, 255, 1);
			text-decoration: none;
		}
	}
}

// car history report today form
.carReport-form{
	padding-left: 20px;
	margin-top: 36px;
	margin-bottom: 60px;
	text-align: center;
	input[type=text]{
		.box-shadow(0px 0px 0px 7px #d9d9d9);
		font-size: 18px;
		padding-top: 0;
		padding-bottom: 0;
		line-height: 26px;
		height: 60px;
		max-width: 274px;
		display:inline-block;
		padding-left: 19px;
	}
	.control-label{
		font-family: Montserrat;
		font-size: 24px;
		margin-bottom: 17px;
		text-align: center !important;
		line-height: 28px;
		letter-spacing: -2px;
		padding: 0 24px;
	}
	.btn-block{
		max-width: 274px;
		color: #fff;
		background-color: #ffa716;
		border-color: transparent;
		background-image: -webkit-linear-gradient(top, #ffa716 0%, #ff9612 100%);
		background-image: -o-linear-gradient(top, #ffa716 0%, #ff9612 100%);
		background-image: linear-gradient(top bottom, #ffa716 0%, #ff9612 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFA716', endColorstr='#FFFF9612', GradientType=0);
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
		background-repeat: repeat-x;
		border-color: #ce8000;
		border-width: 0;
		color: #fff;
		text-shadow: 0 1px 2px rgba(80, 80, 80, 0.6);
		white-space: normal;
		height:60px;
		margin-top: -7px;
		text-transform: uppercase;
		display:inline-block;
		letter-spacing: 1.7px;
		& + p{
			margin-top: 19px;
		}
		.box-shadow(0px 0px 0px 7px #d9d9d9);
		&:hover, &:active{
	        color: #fff;
	        background-color: #e28d00;
	        border-color: transparent;
		}
	}
	span[data-toggle="popover"]{
		&:hover, &:focus{
			text-decoration: underline;
			color: @brand-primary;
			cursor: pointer;
		}
	}
}
.ad{
	width:300px;
	height: 251px;
	margin:0 auto;
	margin-left: 20px;
	background-color: #cccccc;
	display: block;
	float: none;
	& + .ad{
		margin-top: 14px;
	}
}

//
.secondaryForm{
	&.fullwidth{
		background: url(../images/lower_section.jpg) no-repeat center center transparent;
		background-size: cover;
	}
	min-height: 281px;
	padding-top: 51px;
	//background-size: cover;
	a{ 
		color: #fff;
	}
}
.carReport-form-secondary {
	text-align: center;
	max-width: 650px;
	width: 100%;
	margin: 0 auto;
	.label-br{
		display:block;
		h1{
			margin: 0;
		}
		& + .form-group{
			@media (min-width: @grid-float-breakpoint) {
				width: 450px;
			}
		}
	}
	input[type=text]{
		.box-shadow(0px 0px 0px 7px #169e7c);
		.box-shadow(0px 0px 0px 7px rgba(20, 158, 124, 0.8));
		font-size: 18px;
		padding-top: 0;
		padding-bottom: 0;
		line-height: 26px;
		height: 60px;
		display:inline-block;
		padding-left: 32px;
		width:100%;
		@media (max-width: @grid-float-breakpoint) {
			max-width: 274px;
		}
	}
	.label-br .control-label{
			margin-bottom: 52px !important;
		@media (max-width: @grid-float-breakpoint) {
			margin-bottom: 5px !important;
		}
	}
	.control-label{

		font-size: @font-size-h2;
		font-weight: 700;
		font-family: Montserrat;

		margin-bottom: 52px;
		text-align: center;
		line-height: 28px;
		letter-spacing: -1.6px;
		padding: 0 24px;
		color: #fff;

		@media (max-width: @grid-float-breakpoint) {
			margin-bottom: 5px !important;
		    font-size: floor((@font-size-base * (1.304347826086957)));
		    line-height: 1.3;			
		}
	}
	.btn-block{
		color: #fff;
		background-color: #ffa716;
		border-color: transparent;
		background-image: -webkit-linear-gradient(top, #ffa716 0%, #ff9612 100%);
		background-image: -o-linear-gradient(top, #ffa716 0%, #ff9612 100%);
		background-image: linear-gradient(to bottom, #ffa716 0%, #ff9612 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFA716', endColorstr='#FFFF9612', GradientType=0);
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
		background-repeat: repeat-x;
		border-color: #ce8000;
		border-width: 0;
		color: #fff;
		text-shadow: 0 1px 2px rgba(80, 80, 80, 0.6);
		white-space: normal;
		height:60px;
		text-transform: uppercase;
		display:inline-block;
		letter-spacing: 1.7px;
		& + p{
			margin-top: 19px;
		}
		.box-shadow(0px 0px 0px 7px #169e7c);
		.box-shadow(0px 0px 0px 7px rgba(20, 158, 124, 0.8));
		&:hover, &:active{
	        color: #fff;
	        background-color: #e28d00;
	        border-color: transparent;
		}
			max-width: 194px;
		@media (min-width: @grid-float-breakpoint) {
			margin-left: -12px;
		}
		@media (max-width: @grid-float-breakpoint) {
			margin-top: -7px;
			max-width: 274px;
		}
	}
	span[data-toggle="popover"]{
		color: #fff;
		&:hover,&:focus{
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.homeForm{
	background: url(../images/home_hero_1.jpg) no-repeat center center transparent;
	min-height: 311px;
	padding-top: 89px;
	padding-bottom: 57px;

	background-size: cover;

	a{
		color: #fff;
	}

	.carReport-form-secondary{
		.control-label,
		h1 .control-label{
			letter-spacing: -1.2px;
			max-width: 650px;
			width: 100%;
			margin:0 auto;
			font-family: 'Montserrat', sans-serif;
			font-size: 43px;
			line-height: 56px;
			small{
				margin-top: 5px;
				margin-bottom: 14px;
				display: block;
				font-family: "Roboto", sans-serif;
				font-weight: 400;
				font-size: 20px;
				letter-spacing: -0.6px;
				color: inherit;
				line-height: inherit;
			}

			@media (max-width: @grid-float-breakpoint) {
			    font-size: floor((@font-size-base * (1.714285714285714)));
			    line-height: 1.12;
				small{
					font-size: 17px;
					line-height: 1.2;
				}
			}			
		}
		.btn-block + p{
			margin-top: 29px;
			letter-spacing: 0;
			font-size: 16px;
		}
	}
}

// Product Features
h2 + .product-features{
	margin-top: 27px;
}
.product-features{
	& + .product-features{
		margin-top: 46px;
	}
	color: #8e8e8e;
	h5{
		font-size: 21px;
		color: #4d4d4d;
		margin-bottom: 3px;
		margin-top: 9px;
	}
	@media (min-width: @grid-float-breakpoint) {
		img{
			float: left;
			margin-right: 29px;
			& + h5 ~ p{
				padding-left: 170px;
			}
		}
	}
}


// Section Box

.sectionBox{
	padding-top: 33px;
	padding-bottom: 33px;
	background-position: center center;
	display: block;
	width: 100%;
	background-size: cover;
	.invert{
		color: #fff;
		[class^="icon-"], [class*=" icon-"], .iconmoon{
			color: #fff;
		}
		p:hover{
			[class^="icon-"], [class*=" icon-"], .iconmoon{
				color: #fff;
			}			
		}
	}	
	.row > div > h2{
		margin-top: 0;
	}
	> h2{
		max-width: 710px;
		margin-left: auto;
		margin-right: auto;
		@media (max-width: @grid-float-breakpoint) {
			max-width: 310px;
		}

	}
}

// view-latest-media-blog
#latest-news-blog .view-header{
	h3{
		.h2;
	}
}
#block-views-latest-media-blog-block-1,
#block-views-latest-media-blog-block-2{
	.block-title{
		font-size: 30px;
		color: @brand-primary;
		margin: 0;
		margin-bottom: 54px;
	}
}
.no-padding{
	padding: 0;
}
.sectionBox .view-latest-media-blog{
	.views-row{
		min-height: 145px;
		&:first-child{
			~ .views-row {
				margin-top: 28px;
			}
		}
	}
}

// 
table.table-comparison,
.pinned table.table-bordered {
	letter-spacing: -1px;
	width: 100%;
	margin: 0 auto;
	border: 0;
	margin-top: 3px;
	thead{
		th{
			color: #4d4d4d;
			background-color: transparent;
			padding: 0 !important;
			border-color: transparent !important;
			//border: 0 !important;
			&:before{
				display: block; 
				content: '';
				width: 100%;
				height: 1px;
				background: #dddddd;
				margin-left: -1px;
				padding-right: 1px;
				box-sizing: initial;
			}

			&:first-child{
				@media (min-width: @grid-float-breakpoint) {
					span{
						text-align: right;
						//border-right: 1px solid #ff9f00;
							// min-width: 378px;
							padding-right: 34px;
							&:after{
								display: none;
							
							}
					}
					& ~ th{
							max-width: 70px;
						span{
							min-width: 68px;
							text-align: center;
						}
					}

				}
			}
			span{
				position: relative;

				background-color: #eeeeee;
				padding: 8px 0;
				width: 100%;
				display: block;
				//border-left: 1px solid #dddddd;
				//border-right: 1px solid #dddddd;

				box-sizing: inherit;	

				&:before{
					content: '';
					position: absolute;
					height: 100%;
					width: 1px;
					display: block;
					background-color: #dddddd;
					left: -1px;
					top:0;
				}
				&:after{
					content: '';
					position: absolute;
					height: 100%;
					width: 1px;
					display: block;
					background-color: #dddddd;
					right: -1px;
					top:0;
				}

			}
			&:first-child{
				color: #4d4d4d;
				border-color: transparent !important;

			}
		}
		tr:first-child{
			& > th:first-child + th{
				// position: relative;
				z-index: 200;
				span{
					background-color: #efeadd;
					//border-left-color: #ff9f00;
					&:before{
						background-color: #ff9f00;	
					}						
				}
				& + th{
					padding-right: 0;
					padding-left: 0;
					span{
						&:before{
							background-color: #ff9f00;	
						}	
					}			

				}
				z-index: 100;

				&:before{
					text-transform: uppercase;
					content: 'Best \AValue';
					white-space: pre;
					display: block;
					//margin-top: -12px;
					width: 100%;
					height: 43px;
					letter-spacing: 0.5px;
					background-color: #ff9f00;
					//position: absolute;
					//top: -100%;
					//left: -1px; 
					color: #fff;
					text-align: center;
					padding: 7px 0 0;
					line-height: 16px;
					border: 1px solid #ff9f00;
					border-bottom:0; 
					.border-top-radius(6px);
					text-shadow: 0 1px 2px rgba(80, 80, 80, 0.6);

				}				
				border-top-color: #ff9f00;
			}
		}
	}
	tbody{
		tr:last-child{
			& > td:first-child + td{
				border-bottom-color: #ff9f00;
			}
		}
	}	
	
	td{
		background-color: #fff;
	}
	td:first-child {
		border-right-color: transparent;
		text-align: right;
		
		//padding-right: 34px;	

		//max-width: 150px;
		color: #8e8e8e;
		border-right-color: #ff9f00;

		
		& + td{
			background-color: #fffaed;
			color: #ffb600;
			border-left-color: #ff9f00;
			border-right-color: #ff9f00;
		}
		& ~ td{
			z-index: 1;
			// max-width: 27px;
			// min-width: 27px;
			text-align: center;
			//padding-right: 4px !important;
			//padding-left: 4px !important;			
		}
	}

	.glyphicon{
		color: #cccccc;
		margin: 10px 0;
	}
	.glyphicon-ok{
		color: #ffb600;  
	}


	@media (min-width: @grid-float-breakpoint) {
		td:first-child,
		th:first-child{
			letter-spacing: 0.5px;
		}
		td:first-child{
			padding-right: 34px;
		}
	}		
 
}

.table-responsive .table-comparison{
	margin-top: 100px;
	margin-bottom: 10px;
}

// custom list on mobile
.list-xs{
	@media (max-width: @grid-float-breakpoint) {
		div:not(.row),
		li {
			position: relative;
			min-height: 50px;
			display: table;
			margin-bottom: 18px;

			& > [class^="carh-ico"],
		 	& > [class*=" carh-ico"],
			& > [class^="icon-"],
			& > [class*=" icon-"],
			& > .glyphicon{
				position: absolute;
				left: 15px;
				top: 0;
				font-size: 42px;
				& + p{
					text-align: left;
					padding-left: 62px;
					font-size: 18px;
					margin-top: 0;
					margin-bottom: 0;
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}
}

// carousel on mobile
.carousel-xs{
	& > div.carousel-inner{
		& + div{
			display: none;
		}
		.data{
			margin-bottom: 20px;
		}
	} 
	.carh-circle{
		margin-top: 24px;
	}
} 

// disclaimer section
#disclaimer{
	font-size: 15px;
	color: #8e8e8e;
	padding-top: 25px;
	padding-bottom: 21px;
	p{
		padding: 0;
	}
}
.container-md{
	max-width: 885px;
	margin: 0 auto;
}

.carh-circle {
	font-size: 82px;
	box-sizing: content-box;
	padding-bottom: 5px;
	& + p,
	& + strong{
		margin-top: 20px;
		margin-bottom: 0;
		font-size: 19px;
		font-weight: 500;
		& ~ p{
			margin-top: 0;
			margin-bottom: 0;
			margin-bottom: 40px;
		}
	}
	& + p{
		margin-bottom: 40px;
	}
	& + strong{
		display: block;
	}
	&:hover{
	  // -webkit-animation-duration: .5s;
	  // -moz-animation-duration: .5s;
 		//  animation-duration: .5s;
	  // -webkit-backface-visibility: visible;
	  // -moz-backface-visibility: visible;
	  //         backface-visibility: visible;
	  // -webkit-animation-name: flip;
	  // -moz-animation-name: flip;
	  //         animation-name: flip;
	}
}



.social *{
	  // -webkit-animation-duration: .5s;
	  // -moz-animation-duration: .5s;
 		//  animation-duration: .5s;
	&:hover{
	  // -webkit-backface-visibility: visible;
	  // -moz-backface-visibility: visible;
	  //         backface-visibility: visible;
	  // -webkit-animation-name: flip;
	  // -moz-animation-name: flip;
	  //         animation-name: flip;		
	}
}

.a2a_kit{
	margin-top: 47px;
	width: 100%;
	text-align: center;
	display: inline-block !important;
	line-height: 42px;	
  a{
    // -webkit-animation-duration: .5s;
    // -moz-animation-duration: .5s;
     // animation-duration: .5s;
    &:hover,&:focus{
      text-decoration: none;
      // -webkit-backface-visibility: visible;
      // -moz-backface-visibility: visible;
              // backface-visibility: visible;
      // -webkit-animation-name: flip;
      // -moz-animation-name: flip;
              // animation-name: flip;         
    }
  }
  span{
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 0;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  background: none !important; 

  border-radius: 0 !important;
  height: auto !important;
  line-height: 1 !important;
  width: auto !important;  
  position: relative; 
  width: 48px !important;
  height: 50px !important;
  overflow: hidden !important;
    &:before{
      content: '\25CF';
      font-family: arial;
      line-height: 1;
      font-size: 109.7142857142857px;
      color: #cccccc;
      right: -9px;
      top: -36px;
      position: absolute;   
    }
    &:after{
      color: #fff;
      position: absolute;
      right: 0;
      top: 50%;  
      width: 100%;
      text-align: center;
      margin-top: -9.5px;
      font-size: 19px;
      line-height: 1;
    }
  }
  .a2a_s_facebook{
    &:after { content: '\e804'; margin-top: -6.5px;}
  }
  .a2a_s_twitter{
    &:after { content: '\e803'; margin-top: -7.5px;}
  }
  .a2a_s_google_plus{
    &:after { content: '\e805'; margin-top: -6.5px; padding-left: 1px;}
  }  
}



/* Mobile */
@media only screen and (max-width: @grid-float-breakpoint) {
  table.table-comparison {
  	font-size: 13px;
  	border: 0;
    margin-bottom: 0;
    thead{
    	tr{
    		border-top: 0;
    	}
    }
    th span{
    	display: block;
    	min-width: 70px;
    }
  }
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    width: 145px;
    overflow: hidden;
    overflow-x: scroll;
    //border-right: 1px solid #ccc;
    //border-left: 1px solid #ccc;
  }
  .pinned table {
  	font-size: 13px;
    border-right: none;
    border-left: none;
    width: 100%;
  	border: 0;

  }
  .pinned table td {
    white-space: inherit;
    padding-right: 8px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    vertical-align: middle !important;
    line-height: 15px !important;
    border-right-color: transparent !important;
  }
  .pinned table th span{
    padding-right: 8px !important;
    text-align: right;
  }
  .pinned td:last-child {
    border-bottom: 0;
  }
  div.table-wrapper {
    position: relative;
    margin-bottom: 20px;
    //overflow: hidden;
    margin-top: 60px;
    // border-right: 1px solid #ccc;
    .table-nav{
    	position: absolute;
    	right: 0;
    	top: 0;
    	z-index: 99999;

	    &.bottom{
	    	top: auto;
	    	bottom: -34px;
	    }
    	button{
    		background-color: #fff;
    		border: 1px solid #cbcbcb;
    		color: #8b8b8b;
    		&.disabled{
    			background-color: transparent;
    			color: #c2c2c2;
    			border: 0;
    		}
    	}
    }
  }
  div.table-wrapper div.scrollable {
  	z-index: 100;
    margin-left: 144px;

  table td {
  	&:first-child{
    white-space: inherit;
    padding-right: 8px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    vertical-align: middle !important;
    line-height: 15px !important;
  	}
  }

  th span{
  	text-align: center;
  }

    
  }
  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
  }
  table.table-comparison td,
  table.table-comparison th {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
  table.table-comparison th:first-child,
  table.table-comparison td:first-child,
  table.table-comparison td:first-child,
  table.table-comparison.pinned td {
    display: none;
  }
}

td.none {
  background: transparent;
  border: none;
}

.scrollable > .table-comparison{
	th, td{
		overflow: visible;
		&:first-child{
			// visibility: hidden;
			// white-space: inherit;
  			font-size: 13px;
  			max-width: initial;
  			padding-right: 0;
  			& + th{
  				span{
  					&:after{
  						background-color: #ff9f00;
  					}
  				}
  			}
		}
	}
}

/* daron added 02062014 */

.testimonials_media a{
  color:#fff !important;
  text-decoration:underline;
}

.fullwidth{
	background-size: cover;
}



//media section
#as-seen-and-reported-in-the-media{
	border-bottom: 1px solid #dcdfe1;
	text-align: center;
	padding-top: 26px;
	img{
		max-height: 44px;
		width: auto;
	}
		span + img{
			margin-left: 41px;
			& + img{
				margin-left: 34px;
				& + img{
					margin-left: 38px;
					& + img{
						margin-left: 35px;
					}					
				}
			}
		}
	
	@media (max-width: @grid-float-breakpoint) {
		padding-bottom: 13px;	
		img{
			max-height: 34px;
		}		
		span{
			display: block;
			margin-bottom: 15px;
			&+ img{
				margin-left: 0;
			}

		}		
	}
}


// Side Bar Tabs
	.view-mode-sidebar_minumum{
		padding-left: 111px;
		position: relative;
		margin-bottom: 43px;
		font-size: 12px;
		img{
			position: absolute;
			left: 0;
			top: 0;
		}
		h5{
			margin-top: 0;
			margin-bottom: 0;
			font-size: 13px;
			line-height: 1.2;
			font-weight: 700;
		}
	}
	.view-latest-media-blog.view-display-id-block_3,
	.view-latest-media-blog.view-display-id-block_5,
	.view-latest-media-blog.view-display-id-block_6 {
		// margin-left: 20px;
		// margin-right: 20px;
		margin-left: 20px;
		.nav{
			margin-top: 50px;
			border:0;
			li{
				width: 50%;
				margin-bottom: 0;
				a{
					font-size: 15px;
					font-weight: 700;
					padding: 22px 5px 14px;
					color: @gray-dark; 
					background-color: #fff;
					border:0;
					border-bottom: 5px solid #eeeeee !important;
					margin: 0;
					text-align: center;
					border-radius: 0 0 0 0;
					&:hover, &:focus{
						border:0;
					}
				}
				&:first-child{
					a{
						border-radius: 5px 0 0 0;
						overflow: hidden;	
					}
						& ~ li{
							a{
								border-radius: 0 5px 0 0;
							}
						}					
				}
				&.active{
					a{
						border-bottom-color: #ffb700 !important; 
					}
				}
			}
		}
		.tab-content{
			background-color: #fff;
			padding-top: 29px;
			padding-left: 17px;
			padding-right: 17px;
			padding-bottom: 35px;
			border-radius: 0 0 5px 5px;
			margin-bottom: 50px;

		}
	}

	.view-latest-media-blog.view-display-id-block_4,
	.view-all-tags.view-display-id-block{
		margin-bottom: 60px;
		.view-content{
			margin-left: 20px;
			background-color: #fff;
			border-radius: 5px;
			padding-right: 11px;
			padding-left: 10px;
			padding-top: 18px;
			padding-bottom: 60px;
			> div{
				display: inline-block;
			}
			h3{
				border-bottom: 5px solid #eeeeee;
				color: #4d4d4d;
				margin: 0 -18px 0 -10px;
				padding-left: 21px;
				padding-bottom: 16px;
				font-size: 15px;
				margin-bottom: 14px;
			}
			a{
				background-color: #eeeeee;
				border-radius: 5px;
				color: #4d4d4d;
				margin-left: 7px;
				padding-left: 17px;
				padding-right: 20px;
				padding-top: 3px;
				padding-bottom: 6px;
				font-size: 13px; 
				display: inline-block;
				margin-bottom: 5px;
			}
		}
	}
	.view-latest-media-blog.view-display-id-block_4{
		.view-content{
			> div{
				display: block;
			}
		}
	}
// Article section & tags page
	.nextpost{
		margin: 25px 0 5px;
		width: 100%;
		padding: 0;
		font-size: 21px;
		.clearfix;
		li.prev{
			float: left;
		}
		li.next{
			float: right;
		}		
	}
	.term-listing-heading{
		h2{
			font-size: 19px;
			margin-bottom: 60px;
			small{
				.transition(font .6s ease);
				a{
					// border: 1px solid @brand-primary;
					// border-radius: 12px;
					// line-height: 1px;
				}
			}
			&:hover,&:focus{
				small{
					font-size: 120%;
					font-weight: bold;
					a{
						text-decoration: none;
					}
				}
			}
			&:before{
			  font-family: "fontello";
			  font-style: normal;
			  font-weight: normal;
			  speak: none;
			 
			  display: inline-block;
			  text-decoration: inherit;
			  width: 1em;
			  margin-right: .2em;
			  text-align: center;
			  /* opacity: .8; */
			 
			  /* For safety - reset parent styles, that can break glyph codes*/
			  font-variant: normal;
			  text-transform: none;
			     
			  /* fix buttons height, for twitter bootstrap */
			  margin-right: 7px;
			 
			  /* Animation center compensation - margins should be symmetric */
			  /* remove if not needed */
			  // margin-left: .2em;
			 
			  /* you can be more comfortable with increased icons size */
			  /* font-size: 120%; */
			 
			  /* Uncomment for 3D effect */
			   text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); 			
				content: '\e800';
			}
		}
	}
	.article-wrapper{
		color: #8e8e8e;
		span.date{
			font-size: 14px;
		}
		h4{
			font-size: 21px;
			margin-top: 0;
			margin-bottom: 3px;
		}
		@media (min-width: @grid-float-breakpoint) {
			img{
				float: left;
				max-width: 140px;
				height: auto;
			}
			p{
				letter-spacing: -0.5px;
			}
			a + .right_side,
			img + .right_side{
				padding-left: 172px;
			}
		}
		@media (max-width: @grid-float-breakpoint) {
			img{
				.img-responsive;
				width: 100%;
				margin-bottom: 20px;
			}
			margin-bottom: 38px;
		}

		min-height: 145px;
		&:first-child{
			~ .views-row {
				margin-top: 28px;
			}
		}		
	}
	#article-reference {
		padding-top: 0;
		.block-title,
		.taxonomy-term h2{
			font-size: 40px;
			margin-top: 9px; 
		}
	}
	//disqus
		ul.links.list-inline {
			width: 100%;
			margin: 0;
			position: 0;
			.addtoany {
				width: 100%;
				margin-bottom: 50px;	
			}
		}
		#disqus_thread{
			padding: 20px;
			color: @gray-dark; 
			background-color: #fafafa;
			a{

			}
			p{

			}
			.post-body{

			}
			#footer{
				a{
				}
			}
			.community-name{
			}
		}

// Paragraph Types
	.paragraph-full-text{
		img{
			//.img-responsive;
			max-width: 100%;
			height: auto;
		}
		p{
			margin-bottom: 28px;
			& + h1,
			& + h2,
			& + h3,
			& + h4,
			& + h5,
			& + h6,
			& + .h1,
			& + .h2,
			& + .h3,
			& + .h4,
			& + .h5,
			& + .h6
			{
				margin-top: 32px;
			}
		}
		ul{
			margin-bottom: 28px;
		}
	}

//site map
	#site-map{
		.make-row;
		.container;
		.title{
			.h4;
		}
		& > div {
			&:first-child{
				.make-md-column(12);
				& ~ div{
					.make-md-column(6);
				}
			}
		}
	}
	
// IE Fixes
	html.ie8{

		.sectionBox .view-latest-media-blog .views-row,
		.view-article-listings.view-display-id-block,
		.sectionBox .view-latest-media-blog .views-row, 
		.sectionBox .view-latest-media-blog .views-row .article-wrapper,
		.page-taxonomy-term .article-wrapper{
			.clearfix;
		}

		.article-wrapper img{
			width: 100%;
			height: 100%;
			max-width: 140px;
			min-width: 0;
			max-height: 99px;
			min-height: 0;	
			margin-right: 30px;		
		}
	}

	html.lt-ie9.respondLoaded {



	v\:roundrect {
		z-index: -1 !important;
	}



	// Background size cover iefix
		.data-center{
			.data{
				@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
					position: relative;
					z-index: 0;
					@media (max-width: @screen-md) {
						-ms-behavior: url(../images/backgroundsize.min.htc);
						behavior: url(../images/backgroundsize.min.htc);	
					}
					@media (min-width: @screen-lg) {
						-ms-behavior: url(../images/backgroundsize_.min.htc);
						behavior: url(../images/backgroundsize_.min.htc);		
					}
				}
			}
		}
		
		.secondaryForm.fullwidth,
		.sectionBox.fullwidth,
		.fullwidth,
		.homeForm{
		 	background-repeat: no-repeat;
		    background-size: cover;
		    background-position: 50% 50%;
			position: relative;
			z-index: 8;
			@media (max-width: @screen-md) {
				-ms-behavior: url(../images/backgroundsize.min.htc);
				behavior: url(../images/backgroundsize.min.htc);	
			}
			@media (min-width: @screen-lg) {
				-ms-behavior: url(../images/backgroundsize_.min.htc);
				behavior: url(../images/backgroundsize_.min.htc);		
			}
		}

	// Border radius ie fix

	.dropdown-menu {
	    background-color: #fff !important;

	.img-rounded,
	.img-thumbnail,
	.img-circle,
	.pre,
	.kbd ,
	.form-control,
	.input-sm,
	.form-group-sm .form-control,
	.input-lg,
	.form-group-lg .form-control,
	.btn,
	.btn-link,
	.btn-lg,
	.btn-group-lg > .btn,
	.btn-sm,
	.btn-group-sm > .btn,
	.btn-xs,
	.btn-group-xs > .btn ,
	.caret,
	.dropdown-menu,
	.input-group-lg > .form-control,
	.input-group-lg > .input-group-addon,
	.input-group-lg > .input-group-btn > .btn ,
	.input-group-sm > .form-control,
	.input-group-sm > .input-group-addon,.input-group-sm > .input-group-btn > .btn ,
	.input-group-addon,
	.input-group-addon.input-sm,
	.input-group-addon.input-lg,
	.input-group-addon:first-child,
	.nav-tabs > li > a,
	.nav-tabs.nav-justified > li > a 
	 {
	    behavior: url(../images/border-radius.htc);
	    z-index: 10;
	}
	}
	body.not-front .top-nav > ul > li.dropdown.active-trail > a + ul{
	    behavior: none;
		background-color: #009258 !important;
	}

	input.form-control {
		line-height: 56px;
	}

	//behavior: url(../images/border-radius.htc);
	}